/* import './App.css'; */
import React, { useEffect, useState } from 'react';
import { AuthContextProvider, UserAuth } from './context/AuthContext';
import Account from './pages/Account';
import Signin from './pages/Signin';
import Shop from './pages/Shop';
import Meets from './pages/Meets';
import Discussions from './pages/Discussions';
import Profile from './pages/Profile';
import Contact from './pages/Contact';
import ProfileCard from './pages/ProfileCard';
import RemoveAccount from './pages/RemoveAccount';
import HideProfile from './pages/HideProfile';
import HideNotifications from './pages/HideNotifications';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import Cookies from './pages/Cookies';
import MyMeetEditor from './pages/MyMeetEditor'

import Landing from './pages/NewLanding'

import Chat from './pages/Chat'
import OwnMeets from './pages/OwnMeets'
import AddToHomeScreen from './pages/AddToHomeScreen'
import Admin from './pages/Admin';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { db } from "./config/firebase";
import { doc, getDoc } from 'firebase/firestore';
/* import Protected from './components/Protected';
import slipperLogo from './assets/s-logo.png'
import { addBeforeInstallPromptListener, hasBeforeInstallPromptListener, removeBeforeInstallPromptListener } from './context/InstallPromptService'; */


function ProfileRoute({ hasProfile, children }) {
  return hasProfile ? children : <Navigate to="/landing" replace />;
}

function AuthRoute({ hasAuth, children }) {
  return hasAuth ? children : <Navigate to="/landing" replace />;
}

function AdminRoute({ isAdmin, children }) {
  return isAdmin ? children : <Navigate to="/landing" replace />;
}


function App() {
  const { user } = UserAuth();
  const [userHasProfile, setUserHasProfile] = useState(false);
  const [userHasAuth, setUserHasAuth] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();




  /*   useEffect(() => {
      ////console.log("Appjs: useEffect");
      if (user) {
        const doesUserDocumentExist = async (user) => {
          ////console.log("Appjs: useEffect and user found");
          try {
            const userRef = doc(db, "users", user.uid);
            ////console.log(user.uid);
            ////console.log(userRef);
            setUserHasAuth(true);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists) {
              ////console.log("Appjs: Userdoc found");
              setUserHasProfile(true);
            } else {
              ////console.log("Appjs: no user profile");
              setUserHasProfile(false);
            }
          } catch (error) {
            console.error("Appjs: Error checking user document:", error);
            setUserHasProfile(false);
          } finally {
            setLoading(false); // Mark loading as complete
          }
        };
        doesUserDocumentExist(user);
      } else {
        // Handle the case when user is null (e.g., redirect to login or show a login button)
        setLoading(false); // Mark loading as complete
      }
    }, [user]); */

  /*   useEffect(() => {
      ////console.log("Appjs: useEffect");
      if (user) {
        const handleUserAuthentication = async () => {
          try {
            ////console.log("Appjs: useEffect and user found");
            setUserHasAuth(true);
  
  
  
            const userRef = doc(db, "users", user.uid);
            ////console.log(user.uid);
            ////console.log(userRef);
  
            const userDoc = await getDoc(userRef);
            if (userDoc.exists) {
              ////console.log("Appjs: Userdoc found");
              setUserHasProfile(true);
  
              // Perform additional operations when the user has a profile
              // ...
  
            } else {
              ////console.log("Appjs: no user profile");
              setUserHasProfile(false);
            }
  
            setLoading(false); // Mark loading as complete
  
          } catch (error) {
            console.error("Appjs: Error handling user authentication:", error);
            setUserHasProfile(false);
            setLoading(false); // Mark loading as complete
          }
        };
  
        handleUserAuthentication();
  
        // Cleanup: Exit fullscreen when the component unmounts
        return () => {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          }
        };
      } else {
        // Handle the case when user is null (e.g., redirect to login or show a login button)
        setLoading(false); // Mark loading as complete
      }
  
      if (!hasBeforeInstallPromptListener()) {
        addBeforeInstallPromptListener();
      }
  
      // Cleanup function
      return () => {
        removeBeforeInstallPromptListener();
      };
  
    }, [user]); */

  useEffect(() => {
    const handleUserAuthentication = async () => {
      try {
        setUserHasAuth(true);
        const userRef = doc(db, "users", user.uid);
        
        const userDoc = await getDoc(userRef);
        if (userDoc.exists) {
          console.log("Appjs: Userdoc found");
          setUserHasProfile(true);

          const isAdmin = userDoc.data().admin; // Assuming your admin field is named 'admin'
          console.log("IsAdmin "+isAdmin);
          setUserIsAdmin(isAdmin);
          // Perform additional operations when the user has a profile
          // ...

        } else {
          ////console.log("Appjs: no user profile");
          setUserHasProfile(false);
        }

        //setLoading(false); // Mark loading as complete

      } catch (error) {
        console.error("Appjs: Error handling user authentication:", error);
        setUserHasProfile(false);
        //setLoading(false); // Mark loading as complete
      }
    };

    /* handleUserAuthentication();

    if (!hasBeforeInstallPromptListener()) {
      addBeforeInstallPromptListener();
    }

     // Check if the app has already been installed
     const isAppInstalled = localStorage.getItem('isAppInstalled');
     if (!isAppInstalled) {
       // Automatically show install prompt if available
       showAutomatedInstallPrompt();
     }

    return () => {
      removeBeforeInstallPromptListener();
    }; */

    handleUserAuthentication();

    // Deferred prompt and installation logic
/*     let didUseDeferredPrompt = false; // Flag to track deferred prompt usage

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent immediate prompt

      deferredPrompt = e;
      didUseDeferredPrompt = true; // Set flag if encountered
    };

    if (!hasBeforeInstallPromptListener()) {
      addBeforeInstallPromptListener(handleBeforeInstallPrompt);
    }

   

    // Display prompt after login for non-installed users
    const handleLogin = async () => {
      // ... your login logic ...

      if (!didUseDeferredPrompt && !isAppInstalled()) {
        showAutomatedInstallPrompt(); // Use separate logic if needed
      }
    };

    handleLogin(); // Trigger after login

    return () => {
      removeBeforeInstallPromptListener();
    }; */

    /* if (!hasBeforeInstallPromptListener()) {
      addBeforeInstallPromptListener();
    }

    // Cleanup function
    return () => {
      removeBeforeInstallPromptListener();
    }; */

  }, [user]);



  /*   useEffect(() => {
      ////console.log("Appjs: useEffect");
      if (user) {
        const doesUserDocumentExist = async (user) => {
          ////console.log("Appjs: useEffect and user found");
          try {
            const userRef = doc(db, "users", user.uid);
            ////console.log(user.uid);
            ////console.log(userRef);
            setUserHasAuth(true);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists) {
              ////console.log("Appjs: Userdoc found");
              setUserHasProfile(true);
    
              // Request fullscreen when user is authenticated and has a profile
              if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
              }
    
            } else {
              ////console.log("Appjs: no user profile");
              setUserHasProfile(false);
            }
          } catch (error) {
            console.error("Appjs: Error checking user document:", error);
            setUserHasProfile(false);
          } finally {
            setLoading(false); // Mark loading as complete
          }
        };
        doesUserDocumentExist(user);
      } else {
        // Handle the case when user is null (e.g., redirect to login or show a login button)
        setLoading(false); // Mark loading as complete
      }
    
      // Cleanup: Exit fullscreen when the component unmounts
      return () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      };
    }, [user]); */


  useEffect(() => {
    // Check if both conditions are met
    if (userHasAuth && userHasProfile && !userIsAdmin) {
      ////console.log("App.js: should now redirect to meets");
      navigate('/meets');
    }
    if (userIsAdmin) {
      ////console.log("App.js: should now redirect to meets");
      navigate('/admin');
    }
  }, [userHasAuth, userHasProfile, userIsAdmin]);


  /*   if (loading) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000, // Ensure it's above other elements
            textAlign: 'center',
          }}
        >
          <img
            src={slipperLogo}
            alt="Loading..."
            style={{
              width: 'auto', // Adjust the size as needed
              height: 'auto',
            }}
          />
        </div>
      );
    } */


  return (
    <div className='my-container'>
      <AuthContextProvider>
        {/*  {loading && <div>Loading...</div>} */}
        <Routes>

          <Route path='/' element={<Landing />} />
          <Route path='/landing' element={<Landing />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/policy' element={<Policy />} />
          <Route path='/cookies' element={<Cookies />} />
          <Route path='/signin' element={<Signin />} />


          <Route
            path="/admin"
            element={
              <AdminRoute isAdmin={userIsAdmin}>
                <Admin />
              </AdminRoute>
            }
          />

          <Route
            path="/meets"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Meets />
              </AuthRoute>
            }
          />

          <Route
            path="/ownmeets"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <OwnMeets />
              </AuthRoute>
            }
          />

          <Route
            path="/account"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Account />
              </AuthRoute>
            }
          />

          {/*           <Route
            path="/profilecard"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <ProfileCard />
              </AuthRoute>
            }
          /> */}

          <Route
            path="/profilecard/:uid"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <ProfileCard />
              </AuthRoute>
            }
          />


          <Route
            path="/profile"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Profile />
              </AuthRoute>
            }
          />


          <Route
            path="/contact"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Contact />
              </ProfileRoute>
            }
          />
          <Route
            path="/hideprofile"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <HideProfile />
              </ProfileRoute>
            }
          />
          <Route
            path="/hidenotifications"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <HideNotifications />
              </ProfileRoute>
            }
          />
          <Route
            path="/removeaccount"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <RemoveAccount />
              </ProfileRoute>
            }
          />

          <Route
            path="/chat/:chatId"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Chat />
              </ProfileRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Shop />
              </ProfileRoute>
            }
          />
          <Route
            path="/discussions"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Discussions />
              </ProfileRoute>
            }
          />
          <Route
            path="/addtohomescreen"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <AddToHomeScreen />
              </ProfileRoute>
            }
          />



          <Route
            path="/mymeeteditor/:meetId?"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <MyMeetEditor />
              </ProfileRoute>
            }
          />




        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;

