import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import MainNavbar from "../components/MainNavbar";
import {
  collection,
  onSnapshot,
  getDoc,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, messaging } from "../config/firebase";
import { calculateAge, calculateDistanceTo } from "../config/utils";
import Nearby from "../components/Nearby";
import MeetListing from "../components/MeetListing";
import { IconDiamondFilled, IconHeartFilled } from "@tabler/icons-react";
import LogoBar from "../components/LogoBar";
import { useNavigate } from "react-router-dom";
//import { showInstallPrompt, hasBeforeInstallPromptListener, addBeforeInstallPromptListener } from '../context/InstallPromptService';
import { getToken } from "firebase/messaging";

const Meets = () => {
  const { user } = UserAuth();
  //const [meetDocs, setMeetDocs] = useState([]);
  //const [selectedOption, setSelectedOption] = useState('Näytä kaikki tapaamiset');
  const [allMeets, setAllMeets] = useState([]);
  const [filteredMeets, setFilteredMeets] = useState([]);
  /*   const [userMeets, setUserMeets] = useState([]); */
  /*   const [distanceForMeets, setDistanceForMeets] = useState(300);
    const [partnerMinAge, setPartnerMinAge] = useState(18);
    const [partnerMaxAge, setPartnerMaxAge] = useState(99);
    const [genderInterest, setGenderInterest] = useState("Kaikkia");
    const [isSegmentedControlHidden, setIsSegmentedControlHidden] = useState(false); 
    const [currentPage, setCurrentPage] = useState(1); */
  const [meetsPerPage, setMeetsPerPage] = useState(10);
  const [showAllMeets, setShowAllMeets] = useState(false);
  const [userProfileExists, setUserProfileExists] = useState(false);
  const [crystals, setCrystals] = useState(0);
  const navigate = useNavigate();
  const [isNearbyMode, setIsNearbyMode] = useState(false);

  // Function to sort meets by meet_date
  /*   const sortMeetsByDate = (meets) => {
    return meets.slice().sort((a, b) => {
      const dateA = new Date(a.meet_date);
      const dateB = new Date(b.meet_date);
      return dateA - dateB;
    });
  };

  const sortMeetsFromLatest = (meets) => {
    return meets.slice().sort((a, b) => {
      const dateA = new Date(b.meet_date); // Use b instead of a for descending order
      const dateB = new Date(a.meet_date); // Use a instead of b for descending order
      return dateA - dateB;
    });
  };

  useEffect(() => {
    ////console.log("Meets: useEffect called, show all meets is: ");
    ////console.log(showAllMeets);

    const fetchAllMeets = async (meetDocs, now, thisUserData) => {
      ////console.log("Meets: Fetching all meets: ");
      try {
        const allInitialMeets = meetDocs.filter((meet) => {
          const blocked =
            thisUserData.blocked_uids.includes(meet.owner_user) || thisUserData.blocked_by_uids.includes(meet.owner_user);
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          const isClosed = meet.closed;

          return !blocked && !isArchived && !isClosed && !isPast;
        });

        const allMeetsSorted = sortMeetsByDate(allInitialMeets);
        setAllMeets(allMeetsSorted);
      } catch (error) {
        console.error("Error fetching all meets:", error);
      }
    };

    const fetchPreferredMeets = async (meetDocs, now, thisUserData) => {
      try {
        const allInitialMeets = meetDocs.filter((meet) => {
          const blocked =
            thisUserData.blocked_uids.includes(meet.owner_user) || thisUserData.blocked_by_uids.includes(meet.owner_user);
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          const isClosed = meet.closed;

          return !blocked && !isArchived && !isClosed && !isPast;
        });

        const filteredInitialMeets = await Promise.all(
          allInitialMeets.map(async (meet) => {
            let distance = "0";
            try {
              distance = await calculateDistanceTo(meet.geolocation);
            } catch (error) {
              console.error("Error calculating distance:", error);
              distance = "0";

            }

            try {
              //const distance = await calculateDistanceTo(meet.geolocation);

              //if (distance !== null) {
              const age = calculateAge(meet.owner_user_birthday);
              const meetsUserAgeRange = age >= thisUserData.partner_min_age && age <= thisUserData.partner_max_age;
              const meetsUserGenderInterest =
                thisUserData.gender_interest === "Sukupuolella ei väliä" || meet.owner_user_gender === thisUserData.gender_interest;
              const meetsDistance = distance <= parseInt(thisUserData.distance_for_meets, 10);

              if (meetsUserAgeRange && meetsUserGenderInterest && meetsDistance) {
                return meet;
              } else {
                ////console.log("Meet does not meet user criteria:", meet);
              }
              //}
            } catch (error) {
              console.error("Error calculating distance:", error);
              return null; // Return a sentinel value (null) to identify errors
            }
          })
        );

        const filteredInitialMeetsWithoutUndefined = filteredInitialMeets.filter((meet) => meet !== undefined && meet !== null);
        const filteredMeetsSorted = sortMeetsByDate(filteredInitialMeetsWithoutUndefined);
        setFilteredMeets(filteredMeetsSorted);
        ////console.log("fetchPreferredMeets, filteredMeets has now " + filteredMeets.length + " items")
      } catch (error) {
        console.error("Error fetching preferred meets:", error);
      }
    };

    const fetchNoProfileMeets = async (meetDocs, now) => {
      try {
        const allInitialMeets = meetDocs.filter((meet) => {
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          return !meet.closed && !isArchived && !isPast;
        });

        const allMeetsForNoProfileUserSorted = sortMeetsByDate(allInitialMeets);
        setAllMeets(allMeetsForNoProfileUserSorted);
      } catch (error) {
        console.error("Error fetching no profile meets:", error);
      }
    };

    async function handleNotifications(userDoc) {
      const existingToken = userDoc.data()?.fcmToken;

      if (typeof existingToken === 'string' && existingToken.trim()) {
        //console.log('FCM token already exists:', existingToken);
        // You can optionally send the existing token to your server for management
        return; // Exit if token exists
      }

      // If no token or token is undefined/null/empty string
      try {
        const currentToken = await getToken(messaging, { vapidKey: 'BCva3Kac0eat-1hAm-Nxvfp9-k0ZgSS8EgKbL_O7oVSlTMxiiHg7R2cI-o6lIAhG8UNvByQGnDBFFbs4oCTpM7k' });

        if (currentToken && currentToken.trim()) {
          await updateDoc(userDoc, { fcmToken: currentToken });
          //console.log('FCM token retrieved and stored in user document.');
        } else {
          //console.log('Failed to retrieve a valid FCM token.');
        }
      } catch (err) {
        console.error('Error retrieving or storing token:', err);
      }
    }

    const fetchMeets = async () => {
      try {
        const snapshot = await getDocs(collection(db, "meets"));
        const meetDocs = snapshot.docs.map((doc) => ({ ...doc.data(), meetId: doc.id }));
        ////console.log("fetchMeets, Meetdocs: " + meetDocs.length);

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {

          handleNotifications(userDoc);

        

          const thisUserData = userDoc.data();
          ////console.log(thisUserData);
          ////console.log("User data existed, crystals: " + thisUserData.gems);
          setCrystals(thisUserData.gems);

          setUserProfileExists(true);

          if (showAllMeets) {
            ////console.log("fetching All meets")
            await fetchAllMeets(meetDocs, new Date(), thisUserData);
          } else {
            ////console.log("fetching just preferred meets")
            await fetchPreferredMeets(meetDocs, new Date(), thisUserData);
          }
        } else {
          setUserProfileExists(false);


          await fetchNoProfileMeets(meetDocs, new Date());

        }
      } catch (error) {
        console.error("Error fetching meets:", error);
      }
    };

    fetchMeets();
   

  }, [user, showAllMeets]);

  const navigateToMeetEditor = () => {
    navigate('/mymeeteditor');
  };

  const navigateToProfile = () => {
    navigate('/profile');
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setMeetsPerPage(meetsPerPage + 10);
    }
  };

*/


  const handleTabClick = () => {
    setIsNearbyMode(!isNearbyMode); // Toggle isNearbyMode state on button click
  };

  return (
    <div className="page-container">
      <LogoBar crystals={crystals} />
      <MainNavbar />

      <div className="tabs">
        <button
          className={!isNearbyMode ? "active" : ""}
          onClick={handleTabClick}
        >
          Selaa tapaamisia
        </button>
        <button
          className={isNearbyMode ? "active" : ""}
          onClick={handleTabClick}
        >
          Selaa käyttäjiä
        </button>
      </div>

      {isNearbyMode ? <Nearby /> : <MeetListing />}

      {/*     {isNearbyMode ? (
        nearbyContent || <div>Ladataan lähistöllä olevia ihmisiä...</div> // Show loading indicator while fetching
      ) : (
        meetsContent || <div>Ladataan tapaamisia...</div> // Show loading indicator while fetching
        )} */}

      {/*   {
        userProfileExists ? (
          <div className="switch-container" >
            <label for="switch">
              <input
                type="checkbox"
                id="switch"
                name="switch"
                role="switch"
                checked={!showAllMeets}
                onChange={() => setShowAllMeets(!showAllMeets)}
              />
              {showAllMeets ? "Näytä kaikki tapaamiset" : "Vain toiveideni mukaiset tapaamiset"}
            </label>
          </div>


        ) : null
      }

      <div onScroll={handleScroll}>
        {showAllMeets && allMeets && allMeets.length > 0 ? (
          allMeets.map((meet) => (
            <div key={meet.id}>
              <MeetCard meet={meet} crystals={crystals} setCrystals={setCrystals} />
            </div>
          ))
        ) : showAllMeets ? (
          <div>
            Tällä hetkellä ei ole aktiivisia tapaamisia, tule tarkistamaan myöhemmin uudelleen.
            <br /><br />
            <button onClick={navigateToMeetEditor}>Tai luo oma tapaaminen tästä</button>
          </div>
        ) : filteredMeets && filteredMeets.length > 0 ? (
          <div>

            {filteredMeets.map((meet) => {
              ////console.log('Meet object:', meet);
              return (
                <div key={meet.id}>
                  <MeetCard meet={meet} crystals={crystals} setCrystals={setCrystals} />
                </div>
              );
            })}
          </div>
        ) : (
          <div>



            Asettamillasi ehdoilla ei ole vielä tarjolla tapaamisia, mutta voit muuttaa mieltymyksiäsi muokkaamalla profiiliasi tai tulla tarkistamaan myöhemmin uudelleen.
            <br />
            <br />
            <button onClick={navigateToProfile}>Muokkaa mieltymyksiä profiilissasi</button>
          </div>
        )}
        </div>
 */}
    </div>
  );
};

export default Meets;
