// UserActivityService.js

const userActivity = {}; // Object to store user activity status

export const setUserActive = (userId) => {
  userActivity[userId] = Date.now();
};

export const isUserActive = (userId, timeout = 5 * 60 * 1000) => {
  const lastActivity = userActivity[userId] || 0;
  const currentTime = Date.now();
  return currentTime - lastActivity < timeout;
};

