import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import slipperLogo from '../assets/SlipperTrans512.png';
/* import slipperLogo from '../assets/slipper-logo.png'; */
import googleLogo from '../assets/google_logo.png';
import { useForm } from 'react-hook-form';
import { IconBrandFacebook, IconBrandLinkedin, IconBrandInstagram, IconArticle, IconBrandThreads } from '@tabler/icons-react';
import pic1 from '../assets/frontpic1.jpg';
import pic2 from '../assets/frontpic2.jpg';
import pic3 from '../assets/frontpic3.jpg';
import pic4 from '../assets/frontpic4.jpg';

import pic6 from '../assets/frontpic6.jpeg';
import pic7 from '../assets/frontpic7.jpg';
import mark1 from '../assets/mark1.jpg';


import avatar1 from '../assets/avatar1.jpeg';
import avatar2 from '../assets/avatar2.jpeg';
import avatar3 from '../assets/avatar3.jpeg';
import avatar4 from '../assets/avatar4.jpeg';
import avatar5 from '../assets/avatar5.jpeg';
import avatar6 from '../assets/avatar6.jpeg';
import avatar7 from '../assets/avatar7.jpeg';

import ss1 from '../assets/screenshot-1.png';
import ss2 from '../assets/screenshot-2.png';
import ss3 from '../assets/screenshot-3.png';
import ss4 from '../assets/screenshot-4.png';
import ss5 from '../assets/screenshot-5.png';
import ss6 from '../assets/screenshot-6.png';
import ss7 from '../assets/screenshot-7.png';


const NewLanding = () => {

    const { googleSignIn, emailSignIn, emailSignUp, user, logOut, resetPassword } = UserAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isDemoEnvironment = process.env.REACT_APP_ENVIRONMENT === 'demo';
    const [loading, setLoading] = useState(false);
    const [isSignUpMode, setSignUpMode] = useState(false);
    const { handleSubmit, register, control, formState: { errors } } = useForm();
    const [loginError, setLoginError] = useState(null);
    const [showPasswordResetView, setShowPasswordResetView] = useState(false);
    const [showPasswordResetConfirmation, setShowPasswordResetConfirmation] = useState(false);
    const [isWebview, setIsWebview] = useState(false);
    //const [userAgentString, setUserAgentString] = useState(null);
    //const [inWebView, setInWebView] = useState(false);
    //const [showPromptMarker, setShowPromptMarker] = useState(false);
    //const [deferredPrompt, setDeferredPrompt] = useState(null);
    //const [isDeferred, setIsDeferred] = useState(false);



   /*  const handleAddToHomeScreen = () => {
        if (deferredPrompt) {
            // Show the installation prompt
            deferredPrompt.prompt();

            // Wait for the user to respond
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    //console.log('User accepted the A2HS prompt');
                    // You can add analytics tracking here
                } else {
                    //console.log('User dismissed the A2HS prompt');
                }

                // Reset the deferred prompt
                setDeferredPrompt(null);
            });
        }
    }
 */


    const handleGoogleSignIn = async () => {
        ////console.log("Google Signin")
        try {
            setLoading(true);
            if (isWebview) { // Check if inside a webview
                // Logic to navigate to external browser for Google Sign In
                window.open('https://slipper.fi', 'target=_blank,noopener,noreferrer');
            } else {
                await googleSignIn();
                ////console.log("Landing: should now redirect to meets")
            }
        } catch (error) {
            ////console.log(error);
        } finally {
            setLoading(false);
            // Request fullscreen when user is authenticated
            /* if (document.documentElement.requestFullscreen) {
              document.documentElement.requestFullscreen();
            } */
        }
    };

    /*     const forgotPassword = async () => {
            try {
                // Prompt user for their email
                const userEmail = window.prompt('Please enter your email address:');
    
                // Check if the email is entered
                if (userEmail) {
                    // Send a password reset email
                    await resetPassword(auth, userEmail);
                    ////console.log('Password reset email sent successfully. Check your inbox.');
                    // Optionally, you can provide feedback to the user that the email has been sent
                } else {
                    ////console.log('Email address is required for password reset.');
                    // Optionally, you can provide feedback to the user that the email is required
                }
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                // Optionally, you can provide feedback to the user about the error
            }
        }; */

    const handleForgotPassword = async (data, e) => {
        ////console.log("handleForgotPassword")
        ////console.log(data)
        //e.preventDefault(); // Prevent the default form submission behavior
        ////console.log("default prevented")
        try {


            await resetPassword(data.email);
            ////console.log("resetPassword called")
            // Optionally, you can provide feedback to the user that the password reset email has been sent

            setShowPasswordResetConfirmation(true);
            //setShowPasswordResetView(false);

        } catch (error) {
            console.error('Error sending password reset email:', error.message);
            // Optionally, you can provide feedback to the user about the error
        }
    };


    const handleOkButtonClick = () => {
        // Reset the view to the default state (signup/signin)
        setShowPasswordResetConfirmation(false);
        setLoginError(false);
    };

    const handleForgotPWButtonClick = () => {
        // Toggle the view without directly calling the forgotPassword function
        setShowPasswordResetView(true);
        setLoginError(false);
    };

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            ////console.log(error);
        }
    };

    const handleEmailSignIn = async (data) => {
        try {
            await emailSignIn(data.email, data.password);
            ////console.log("Signin: Trying to navigate to meets")
            // Request fullscreen when user is authenticated
            /*  if (document.documentElement.requestFullscreen) {
                 document.documentElement.requestFullscreen();
             } */
            navigate('/meets'); // Navigate to /meets after successful sign-in
        } catch (error) {
            ////console.log("Signin, email: " + error);
            setLoginError('Väärä sähköpostiosoite tai salasana.');
        }
    };


    const handleEmailSignUp = async (data) => {

        try {
            await emailSignUp(data.email, data.password);
            navigate('/meets');
        } catch (error) {
            ////console.log(error);
            setLoginError('Jokin meni pieleen rekisteröitymisessä.');
        }
    };



    const toggleAuthMode = () => {
        setSignUpMode(!isSignUpMode);
        setShowPasswordResetConfirmation(false);
        setShowPasswordResetView(false);
        setLoginError(false);
    };

    const refreshPage = () => {
        window.location.reload(true); // Passing true as a parameter forces a bypass of the cache
    };

    const isRunningInWebview = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        // Only calculate and store userAgentString once
        const isWebView = userAgent.includes('fban') || userAgent.includes('fbav') || userAgent.includes('instagram');

        return isWebView;
    };

    useEffect(() => {
        const isWebView = isRunningInWebview();
        // Store the result in setInWebView state
        setIsWebview(isWebView);

       /*  const handleBeforeInstallPrompt = (event) => {
            //nothing to do here
            event.prompt();

        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Cleanup function to remove listener on unmount
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        }; */
    }, []); 




    /*  return (
         <div className="prelogin-container">
             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                 {loading && <div>Loading...</div>}
                 {!loading && (
                     <>
                         <img
                             src={slipperLogo}
                             alt="Slipper Logo"
                             style={{ width: '50%', height: 'auto' }}
                         />
                         <br /><br />
                         {isDemoEnvironment && (
                             <div>
                                 Demo-ympäristö
                                 <br /><br />
                             </div>
                         )}
 
                         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
 
                             <form onSubmit={handleEmailSignIn}>
                                 <input
                                     type='email'
                                     placeholder='Email'
                                     value={email}
                                     onChange={(e) => setEmail(e.target.value)}
                                 />
                                 <input
                                     type='password'
                                     placeholder='Password'
                                     value={password}
                                     onChange={(e) => setPassword(e.target.value)}
                                 />
                                 <button type='submit'>Sign In</button>
                             </form>
 
 
                             <form onSubmit={handleEmailSignUp}>
                                 <input
                                     type='email'
                                     placeholder='Email'
                                     value={email}
                                     onChange={(e) => setEmail(e.target.value)}
                                 />
                                 <input
                                     type='password'
                                     placeholder='Password'
                                     value={password}
                                     onChange={(e) => setPassword(e.target.value)}
                                 />
                                 <button type='submit'>Sign Up</button>
                                 <button onClick={handleSignOut} >Kirjaudu ulos</button>
                             </form>
 
                             <button
                                 style={{ border: 'none', padding: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}
                                 onClick={handleGoogleSignIn}
                             >
                                 <img src={googleLogo} alt="Google Logo" style={{ maxWidth: '48px', marginRight: '10px' }} />
                                 <span style={{ color: 'white' }}>Jatka Googlella</span>
                             </button>
 
                         </div>
 
                         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                             <small style={{ marginTop: '20px' }}>
                                 Emme julkaise mitään, emmekä jaa tietojasi eteenpäin ilman lupaasi. Nimeäsi, kuvaasi tai muita tietojasi ei näytetä kenellekään muulle käyttäjälle ellet niitä itse profiilisi täytä ja sen jälkeen luo omaa tapaamista.
                             </small>
 
                             <footer style={{ marginTop: '20px' }}>
                                 <small style={{ marginTop: '20px' }}>
                                     Rekisteröitymällä ja sisäänkirjautumalla hyväksyt seuraavat:
                                 </small>
                                 <br /><br />
 
 
                                 <Link to="/terms">Käyttöehdot</Link>
                                 <Link to="/policy">Tietosuojakäytäntö</Link>
                                 <Link to="/cookies">Evästeiden käyttö</Link>
                                 <br /><br />
                                 <small>Slipper v{process.env.REACT_APP_VERSION}</small>
                             </footer>
                         </div>
                     </>
                 )}
             </div>
         </div>
     );
 }; */

    return (
        <div className="prelogin-container">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px'/* , height: '100vh' */ }}>



                <img
                    src={slipperLogo}
                    alt="Slipper Logo"
                    style={{ width: '50%', height: 'auto' }}
                />
                <br />
                {isDemoEnvironment && (
                    <div>
                        Demo-ympäristö
                        <br /> <br />
                    </div>
                )}


                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {isSignUpMode && !showPasswordResetView ? (
                        <form onSubmit={handleSubmit(handleEmailSignUp)} style={{ width: '70%' }}>
                            <input
                           
                                type="email"
                                placeholder="Sähköposti"
                                {...register("email", { required: 'Sähköposti on pakollinen' })}
                                style={{ 
                                    width: '100%', 
                                    marginBottom: '10px', 
                                    color: 'black', // Set text color to black
                                    backgroundColor: 'white' // Set background color to white
                                  }}
                                autoComplete="off"
                            />

                            <input
                            
                                type="password"
                                placeholder="Salasana"
                                autoComplete="new-password"
                                {...register("password", {
                                    required: 'Salasana on pakollinen',
                                    minLength: {
                                        value: 6,
                                        message: 'Salasanan on oltava vähintään 6 merkkiä pitkä.'
                                    },
                                    /* pattern: {
                                      value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                                      message: 'Salasanassa tulee olla vähintään yksi iso kirjain, yksi numero ja yksi erikoismerkki.'
                                    }, */
                                })}
                                style={{ 
                                    width: '100%', 
                                    marginBottom: '10px', 
                                    color: 'black', // Set text color to black
                                    backgroundColor: 'white' // Set background color to white
                                  }}
                            />
                            {errors.password && <small className="error">{errors.password.message}</small>}

                            <button className="contrast" type='submit' style={{ width: '100%' }}>Rekisteröidy</button>
                        </form>
                    ) : (
                        !showPasswordResetView && (
                            <form onSubmit={handleSubmit(handleEmailSignIn)} style={{ width: '70%' }}>
                                <input
                                    type='email'
                                    placeholder='Sähköposti'
                                    {...register('email', { required: 'Sähköposti on pakollinen' })}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                />
                                {errors.email && <small className="error">{errors.email.message}</small>}

                                <input
                                    type='password'
                                    placeholder='Salasana'
                                    {...register('password', { required: 'Salasana on pakollinen' })}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                />
                                {errors.password && <small className="error">{errors.password.message}</small>}

                                <button type='submit' style={{ width: '100%' }}>Kirjaudu sisään</button>
                            </form>
                        ))}






                    {loginError && <small>Väärä sähköpostiosoite tai salasana</small>}
                    {!showPasswordResetView && !showPasswordResetConfirmation ? (
                        <small style={{ /* margin: '5px 0', */ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleForgotPWButtonClick}>
                            Unohditko salasanasi?
                        </small>
                    ) : null}

                    {showPasswordResetView && !showPasswordResetConfirmation && (

                        <form onSubmit={handleSubmit(handleForgotPassword)} style={{ width: '100%' }}>
                            <label htmlFor="email">Anna sähköpostiosoitteesi:</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Sähköposti"
                                {...register("email", { required: "Sähköposti on pakollinen" })}
                                style={{ width: '100%' }}
                            />
                            {errors.email && <small className="error">{errors.email.message}</small>}
                            <button style={{ width: '100%' }} type='submit'>Lähetä resetointilinkki</button>
                        </form>

                    )}

                    {showPasswordResetConfirmation && (
                        <div>
                            {/* Optionally, you can provide additional styling for the success message */}
                            <p>
                                Sinulle on lähetetty antamaasi sähköpostiosoitteeseen linkki salasanan resetoimiseksi.
                                Resetoi salasana ja kirjaudu sen jälkeen uudella tunnuksella. Muista tarkistaa myös roskaposti!
                            </p>
                            <button onClick={handleOkButtonClick}>Ok</button>
                        </div>
                    )}

                    <small style={{ margin: '10px 0', textDecoration: 'underline', cursor: 'pointer' }} onClick={toggleAuthMode}>
                        {isSignUpMode ? 'Onko sinulla jo tunnus? Kirjaudu sisään' : 'Eikö sinulla ole vielä tunnusta? Rekisteröidy tästä'}
                    </small>
                    <br />

                    {isWebview && (
                        <div>
                            <small style={{ color: '#00ffff' }}>
                                Mikäli käytät tätä sovellusta Instagramin tai Facebookin sisäisessä selaimessa, ei sisäänkirjautuminen toimi. Pyydämme siirtymään käyttämään normaalia nettiselainta, kuten Chrome tai Safari.
                            </small>
                            <br />
                        </div>
                    )}
                    <button class="contrast"
                        style={{ border: 'none', padding: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center', width: '55%' }}
                        onClick={handleGoogleSignIn}
                    >
                        <img src={googleLogo} alt="Google Logo" style={{ maxWidth: '48px', marginRight: '10px' }} />
                        <span style={{ color: 'black', fontWeight: 'bold' }}>Jatka Googlella</span>

                    </button>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                   {/*  <small style={{ marginTop: '20px' }}>
                        Emme julkaise mitään, emmekä jaa tietojasi eteenpäin ilman lupaasi. Nimeäsi, kuvaasi tai muita tietojasi ei näytetä kenellekään muulle käyttäjälle ellet niitä itse profiilisi täytä ja sen jälkeen luo omaa tapaamista.
                    </small> */}

                    <footer style={{ marginTop: '20px' }}>
                        {/* <small style={{ marginTop: '20px' }}>
                            Rekisteröitymällä ja sisäänkirjautumalla hyväksyt seuraavat:
                        </small>
                        <br /> */}

                        <Link to="/terms">Käyttöehdot | </Link>
                        <Link to="/policy">Tietosuojakäytäntö | </Link>
                        <Link to="/cookies">Evästeiden käyttö</Link>
                        <br /><br />
                        <small>Slipper v{process.env.REACT_APP_VERSION}</small>
                        <button style={{ textDecoration: 'underline', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }} onClick={refreshPage}>Lataa uusin versio</button>

                    </footer>
                </div>

{/*                 <div
                    style={{
                        textAlign: "center",
                        margin: "20px",
                        border: "2px solid #FFD700",
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    <span style={{ color: "#FFD700", margin: "10px" }}>
                        Haluatko käyttää Slipperiä nopeammin? Lisäämällä sovelluksen laitteesi aloitusnäytölle, Slipper toimii aivan kuten natiivisovellus vaikka onkin teknisesti websivusto.</span>
                </div> */}

                <h6 style={{ color: '#BFFFDF' }}>Seuraa meitä:</h6>

                <div class="social-media-row">
               
                
                    {/* <span>Seuraa Slipperiä:</span> */}
                    <a href="https://www.instagram.com/slipper_app" target="_blank" rel="noopener noreferrer">
                        <IconBrandInstagram size={40} stroke={1.5} />
                    </a>
                    <a href="https://facebook.com/61556716288569" target="_blank" rel="noopener noreferrer">
                        <IconBrandFacebook size={40} stroke={1.5} />
                    </a>
                     <a href="https://www.linkedin.com/company/slipper-dating-app" target="_blank" rel="noopener noreferrer">
                        <IconBrandLinkedin size={40} stroke={1.5} />
                    </a> 
                    {/* <a href="https://www.linkedin.com/company/slipper-dating-app" target="_blank" rel="noopener noreferrer">
                        <IconBrandThreads size={45} stroke={1.5} />
                    </a> */}
                    <a href="https://blog.slipper.fi" target="_blank" rel="noopener noreferrer">
                        <IconArticle size={40} stroke={1.5} />
                    </a>
                </div>
                <br /><br />
                <main className="prelogin-container">

                <div>

                  {/*    <img src={mark1} />
                    <br /><br />
                   <img src={mark3} /> */}

                   
                    <fieldset>
                        <h6>Muut deittisovellukset:</h6>
  <label>
    <input name="publish1" type="checkbox" role="switch" disabled />
    Tyhjiä profiileja
  </label>
  <label>
    <input name="publish2" type="checkbox" role="switch" disabled />
    Tuhansia swaippauksia
  </label>
  <label>
    <input name="publish3" type="checkbox" role="switch" disabled />
    Matcheja, jotka eivät kirjoita
  </label>
  <label>
    <input name="publish4" type="checkbox" role="switch" disabled />
    Hyödyttömät kuukausimaksut
  </label>
  <label>
    <input name="publish5" type="checkbox" role="switch" disabled />
    Loputonta viestittelyä tuloksetta
  </label>
<br/>
  <h6 style={{ color: '#FDD017' }}>Slipper-way:</h6>
  <label style={{ color: '#FDD017' }}>
  <input name="opt-in6" type="checkbox" role="switch" checked disabled />
  Luo tapaaminen
</label>
<label style={{ color: '#FDD017' }}>
  <input name="opt-in7" type="checkbox" role="switch" checked disabled />
  Valitse seuralainen
</label>
<label style={{ color: '#FDD017' }}>
  <input name="opt-in8" type="checkbox" role="switch" checked disabled />
  Koe aitoja kohtaamisia
</label>

</fieldset>

<div style={{ width: '100%', overflowX: 'auto' }}> 
      <div className="carousel-slides">
        <div className="carousel-slide"><img src={ss1} alt="Screenshot 1" className="screenshot" /></div>
        <div className="carousel-slide"><img src={ss2} alt="Screenshot 2" className="screenshot" /></div>
        <div className="carousel-slide"><img src={ss3} alt="Screenshot 3" className="screenshot" /></div>
        <div className="carousel-slide"><img src={ss4} alt="Screenshot 4" className="screenshot" /></div>
        <div className="carousel-slide"><img src={ss5} alt="Screenshot 5" className="screenshot" /></div>
        <div className="carousel-slide"><img src={ss6} alt="Screenshot 6" className="screenshot" /></div>
        <div className="carousel-slide"><img src={ss7} alt="Screenshot 7" className="screenshot" /></div>
      </div>
    </div>

   

<br />

<div className="testimonial-container">
    <div className="testimonial">
      <img src={avatar1} alt="User Avatar" className="avatar"/>
      <p className="quote">"Timanttinen sovellus! Peittoaa Tinderin mennen tullen, kunhan käyttäjiä tulee enempi."</p>
    </div>
    <div className="testimonial">
      <p className="quote">"Kurkkasin ja tosi hyvä idea! Itte oon kyllästynyt diipadaapa viesteilyyn ja juurikin tuohon, että luot vain mielikuvia, oletuksia. 
      Treffeille asap ja siitä sitten eteenpäin jos yhteiset halut, tarpeet ja arvomaailmat kohtaa. 
      Toivottavasti linjoille tulee lisää porukkaa ja oivaltavat tämän sovelluksen nerokkuuden!"</p>
      <img src={avatar2} alt="User Avatar" className="avatar"/>
    </div>
    <div className="testimonial">
      <img src={avatar3} alt="User Avatar" className="avatar"/>
      <p className="quote">"Todella hyvä, että sinkkumarkkinoille tulee uusi ja erilainen sovellus. Ja hienoa, että tuossa voi hakea ihan ystäviäkin."</p>
    </div>
    <div className="testimonial">
      <p className="quote">"Todella valtavan hyvä idea ja toteutus kaiken kaikkiaan! Ootan innolla, että porukka löytää Slipperin pariin. Aivan varmasti on muitakin, jotka on kaivanneet juuri tällaista!"</p>
      <img src={avatar4} alt="User Avatar" className="avatar"/>
    </div>
    <div className="testimonial">
      <img src={avatar5} alt="User Avatar" className="avatar"/>
      <p className="quote">"Täytyyhän tätä kokeilla, vaikka tinderit yms ei kiinnosta."</p>
    </div>
    <div className="testimonial">
      <p className="quote">"Kaksi kertaa on Slipperin kautta käyny treffeillä. Toinen oli minun Ilmon treffit ja toinen neitokaisen Ilmon kautta. Mie tykkään tästä kun ei oo semmonen lihatiski meininki kuin muissa"</p>
      <img src={avatar6} alt="User Avatar" className="avatar"/>
    </div>
    <div className="testimonial">
      <img src={avatar7} alt="User Avatar" className="avatar"/>
      <p className="quote">"Ei uskalla. Vielä joku naaras saa satimeen."</p>
    </div>
    
    </div>
                 
                    <br />
                    <section>
                       
                        <h4><em>Mikä ihmeen Slipper?</em></h4>
                        <p>

                            Tiesitkö, että nettideittisovelluksissa useimmilla vain pari prosenttia swaippauksista johtaa osumaan?
                            Ja vaikka saisitkin osuman, niin kukaan ei kirjoita avausviestiä tai vastaa siihen. Osumista vain pieni osa johtaa lopulta kohtaamiseen. Kuulostaako tutulta?
                        </p>
             
                        <p>
                            Slipper suoraviivaistaa tätä mullistavalla takaperoisella deittailukokemuksellaan.
                            Slipperissä luot ensin tapaamisen ja vasta sitten valitset siihen seuralaisen! Kun houkuttimena onkin aito kohtaaminen sovittuine paikkoineen, aikoineen ja aktiviteetteineen ihkaelävän ihmisen kanssa pelkän profiilin sijaan,
                            se antaa tutustumiseen aivan uuden kipinän. Keskustella voit aivan kuten muissakin sovelluksissa. Ja mikään ei pakota tapaamaan ketään ennen kuin olet siihen valmis.</p>
                       
                        <figure>
                            <img src={pic1} alt="Tuhkimo" />
                            <figcaption>
                                Slipper tuo arkeesi ripauksen tähtipölyä.
                            </figcaption>
                        </figure>

                        <h4><em>Mutta Slipperhän tarkoittaa jotain kenkää?</em></h4>

                        <p>
                            Niin tarkoittaa. Tuhkimon lasikenkää (eng. glass slipper) jos tarkkoja ollaan.
                            Yhteensopivuuden ja rakkauden symboli, jonka kanssa nimetön prinssimme laukkasi ympäriinsä etsimässä naista,
                            jolla ilmeisimmin ainoana valtakunnassa oli kyseinen kengänkoko.
                        </p>

                        <p>Slipper on samalla myös kehoitus laittaa kengät jalkaan ja poistua kotoa, loputtoman swaippailun, viestittelyn ja kuvien selaamisen sijaan.
                            Voit valita useista valmiista aktiviteeteista, alueesi tapahtumista tai keksiä ihan oman. Yritykset käyttävät Slipperiä esitelläkseen omia menovinkkejään.</p>
                        
                        <p>"Ditch the apps, put on your shoes!" on yksi sloganimme.</p>
                        <p>Slipperistä voit löytää myös uusia ystäviä, harrastuskavereita tai asiakkaita.</p>
                        <p>
                           Sovellus on yhtälailla sinkuille kuin varatuillekin, unohtamatta eettisesti monisuhteisia.
                            Kaikki kohtaamiset ovat meille samanarvoisia kunhan ihmiset vain ovat rehellisiä elämäntilanteestaan ja tarkoitusperistään.
                        </p>
                        <figure>
                            <img src={pic2} alt="Prinssi" />
                            <figcaption>
                                Nykypäivänä sen juuri sopivan löytäminen on (ainakin tavallaan) helpompaa.
                            </figcaption>
                        </figure>


                        <h4><em>Minun on mahdotonta päättää kenet haluaisin tavata, haluan tietää ihmisestä enemmän.</em></h4>

                        <p>
                            Tämä on yleinen ongelma. Slipperissä tämä on ratkaistu tekemällä deittiprofiilin kentistä valtaosin pakollisia,
                            joten sinulla on aina käytettävissä täydelliset tiedot mahdollisesta seuralaisestasi. Myös profiilikuvien määrä on rajoitettu yhteen, jotta pinnallisuus olisi edes hieman pienemmässä roolissa.

                        </p>

                        <p>Tapaamiseen ilmoittautuessa on kirjoitettava pakollinen avausviesti, joten voit Slipperin myötä sanoa näkemiin myös mykille mätseille.</p>
                        <figure>
                            <img src={pic3} alt="Choice-paralysis" />
                            <figcaption>
                                Kun kaikki profiilit näyttävät samalta ja alkavat olla yhtä puuroa.
                            </figcaption>
                        </figure>

                        <h4><em>Mutta jos tapaamiseeni ilmoittautuukin useampia ihmisiä?</em></h4>

                        <p>
                            Voit lukita tapaamisen kun ensimmäinen tarpeeksi kiinnostava seuralainen ilmaantuu. Näin et kuormitu liian monista ehdokkaista. Jos näköpiirissä siintää useampi mielenkiintoinen tapaus, 
                            mikään ei estä sopimasta vastaavia treffejä toisen ehdokkaan kanssa uuteen ajankohtaan.
                        </p>
                     
                        <p>Voit milloin tahansa poistaa minkä tahansa keskustelun. Slipperistä löytyy tietysti myös estotoiminnot. Kaikki sovellusmekaniikka on rakennettu tukemaan turvallisuutta sekä asiallista käyttäytymistä.
                        </p>
                        <figure>
                            <img src={pic4} alt="Kohtaaminen" />
                            <figcaption>
                                Aitoja kohtaamisia mielikuvien sijaan.
                            </figcaption>
                        </figure>

                        <h4><em>En haluaisi olla noin esillä, mitä nuo tapaamisilmoitukset ovat.</em></h4>

                        <p>
                            Kun luot tapaamisen, voit määrätä sen näkyvän vain suosikeillesi (max 20 kpl). Kyseessä on
                            kuin treffipyyntö, mutta "salainen ihastuksesi" ei tiedä näkyykö se vain hänelle vai kaikille käyttäjille.</p>
                            <p>Tapaamiset yleensäkin näkyvät vain profiilin luoneille käyttäjille, 
                            joten ulkopuoliset eivät niitä pääse selaamaan. Käyttäjälistauksesta voit nyt myös estää näkyvyytesi valituille käyttäjille (esim ex-kumppanille tai -deiteille) niin halutessasi.
                        </p>

                        <p>
                            Ja tietysti voit käyttää Slipperiä passiivisesti ainoastaan ilmoittautuen tapaamisiin, luomatta niitä itse. Keskusteluyhteys kuitenkin vaatii aina tapaamisen luomisen tai ilmoittautumisen.
                        </p>
                      
                        <figure>
                            <img src={pic7} alt="Aamu" />
                            <figcaption>
                                Aika laittaa verkkoja vesille?
                            </figcaption>
                        </figure>

                        {/* <h4><em>Inhoan treffien jälkeistä kiusallista tilannetta, kun pitäisi kertoa mikä fiilis jäi.</em></h4>

                        <p>
                            Aina asiat eivät todellakaan mene kuin satukirjoissa. Ja itseasiassa Tuhkimon ja prinssinkään ensitreffit eivät menneet ihan nappiin.
                            Prinssi oli juhlimisen jäljiltä niin väsynyt ettei jälkikäteen muistanut miltä hänen deittinsä näytti, Tuhkimo taas kadotti ajantajun
                            ja kompuroi portaissa kiirehtiessään taksijonoon, hukaten samalla kenkänsäkin. Mutta sitä sattuu itse kullekin.

                        </p>
                        <p>Myös Slipperissä taika katoaa puolenyön aikaan treffipäivänä ja muutut takaisin kurpitsaksi. Ei sentään. Mutta keskustelu deittisi kanssa tuhoutuu kyllä automaattisesti.
                            Näin selviät helpommalla. Sinun ei tarvitse tehdä tapaamisen jälkeen yhtään mitään. </p>

                        <p>
                            Jos seura miellytti, joudut toki tekemään nopeasti päätöksen jatkosta ennen puoltayötä.
                            Kukaan ei jää enää roikkumaan epätietoisuuteen päiväkausiksi. Tämä kokeellinen ominaisuus tuo ripauksen jännitystä
                            ja taikapölyä arkiseen deittailuun.</p>
                        <p>Älä ole kuin Tuhkimo tai prinssi. Vaihtakaa yhteystiedot ajoissa ja puhukaa niin ei tarvitse kengän kera jahdata ketään jälkikäteen.</p>
                        <figure>
                            <img src={pic5} alt="Aamu" />
                            <figcaption>
                                Aamulla voi harmittaa, kun ei avannut suutaan.
                            </figcaption>
                        </figure> */}

                        <h4><em>Ei mun tapaamisiin ketään ilmoittaudu, tai sitten kukaan ei kiinnosta.</em></h4>

                        <p>
                            Ihmiset ovat ihmisiä. Slipperkään ei pysty uusiksi upeiksi sinkuiksi muuttumaan.
                            Voit kuitenkin milloin tahansa poistaa keskusteluja tai luomasi tapaamisen.
                            Eikä sinun tarvitse valita ketään mikäli ehdokkaat eivät miellytä.
                        </p>
                        <p>
                            Luomasi tapaamiset toimivat samaan aikaan kuin postauksina, joissa jaat persoonaasi ja pidät itsesi muiden seuraa etsivien mielissä. Tapaamisiin lataamasi kuvat myös tulevat näkyville profiilisivullesi somefeedin tapaan ja jäävät sinne rakentamaan yksilöllistä tarinaa juuri sinusta.
                        </p>

                        <p>Ajatus Slipperissä on, että ihmiset, jotka eivät normaaleissa deittisovelluksissa mätsäisi keskenään,
                            kuitenkin voisivat matalalla kynnyksellä kohdata toisensa, koska kysehän on helpoimmillaan vain tunnin kahvihetkestä, lounaasta tai kävelylenkistä. Ensitapaaminen tulisi tietysti aina tapahtua julkisella paikalla.
                        </p>
                        <p>Tämä on paljon paineettomampaa kuin viikkojen viestittelyn perusteella sietämättömäksi kasvaneet odotukset ja pelot vastaako todellisuus mielikuvaa.
                        </p>

                        <p>Usein ihmisillä jää myös esimerkiksi lippuja keikoille, matkoille ja tapahtumiin käyttämättä.
                            Slipper on loistava kanava löytää omistaja toiselle lipulle ja samalla et kenties itsekään joudu menemään yksin.
                        </p>

                        <p>Sovellus on maksuton, mutta saattaa sisältää etukuponkeja ja tarjouksia yhteistyökumppaneiltamme.

                        </p>

                        <p>Tervetuloa mukaan kokeilemaan!

                        </p>
                        <figure>
                            <img src={pic6} alt="Fullmoon" />
                            <figcaption>
                                Voit valita tapaamisen aktiviteetiksi vaikka tämän.
                            </figcaption>
                        </figure>

                    </section>

                    
                </div>
                <footer className="container">


                </footer>
            </main>


            </div>
        </div >
    );
};

export default NewLanding;

