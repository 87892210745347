import React, { useState, useEffect } from 'react';
import { IconDiamond, IconHeart } from '@tabler/icons-react';
import { UserAuth } from '../context/AuthContext';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import slipperImage from '../assets/SlipperTrans512.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LogoBar({ crystals }) {
  const { user } = UserAuth();
  const [heartPoints, setHeartPoints] = useState(null);
  //const [gems, setGems] = useState(null);


  const handleHeartClick = () => {
    // Display a toast when the heart icon is clicked
    ////console.log("You clicked the heart icon")
    toast.info('Sydämet kuvaavat aktiivisuuttasi, saat niitä esimerkiksi tapaamisten luomisesta, ilmoittautumisesta ja kumppanietuuksien klikkailusta');
    // You can customize the toast appearance and duration, see the react-toastify documentation
  };

  const handleCrystalClick = () => {
    // Display a toast when the crystal icon is clicked
    ////console.log("You clicked the crystal icon")
    toast.info('Taikakristallit ovat Slipperin sisäinen valuutta, niillä voit esimerkiksi ilmoittautua tapaamisiin. Löydät lisää kristalleja kumppanietuudet-sivulta');
  };

  /* useEffect(() => {
    
    const getUser = async () => {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      ////console.log("Logobar Userdoc:" + userDoc);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        ////console.log("LogoBar hearts and gems: " + userData.heartPoints + " and gems " + userData.gems + " for user " + userData.uid);
        setHeartPoints(userData.heartPoints);
        setGems(userData.gems);
      } else {
        ////console.log("No such document or data!");
      }
    };
    getUser();
  }, [user]); */


  return (
    <nav className="context-navbar">
      
      <ul>
        <li>

          <img src={slipperImage} alt="Logo" style={{ height: '40px' }} />


        </li>
      </ul>
        <ul>
      <li>   
      <ToastContainer position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        
        />
        </li> 
      </ul>
     {/*  <ul>
       
          
          <li onClick={handleHeartClick}>
 
  {heartPoints !== null && <><IconHeart style={{ color: '#FF3131' }} /> {heartPoints} </>} 
</li>
        <li onClick={handleCrystalClick}>
        {gems !== null && <><IconDiamond style={{ color: '#73FFFF' }} /> {gems} </>} 
         
        </li>
      </ul> */}
    </nav>
  );
}

export default LogoBar;

