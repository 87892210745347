import React, { useEffect, useState } from "react";
import { db, storage } from "../config/firebase";
import { calculateAge, calculateDistanceTo } from "../config/utils";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { UserAuth } from "../context/AuthContext";
import locationsJson from "../assets/locations.json";
import UserPreview from "./UserPreview";
import {
  IconStar,
  IconStarFilled,
  IconSquareX,
  IconStarHalfFilled,
  IconStarOff,
} from "@tabler/icons-react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  GeoPoint,
  updateDoc,
  query,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore"; // Import Firestore functions
import placeholderImage from "../assets/missing_profile_image.jpg";
const ITEMS_PER_PAGE = 15;

const Nearby = () => {
  // Pass currentUser prop
 
  const { user } = UserAuth();
  const [nearbyUsers, setNearbyUsers] = useState([]); // State for nearby users
  const [userLikes, setUserLikes] = useState([]); // State for current user's likes
  const [locations, setLocations] = useState([]);
  const [nearbyUsersWithDistance, setNearbyUsersWithDistance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userToBeShown, setUserToBeShown] = useState(null);
  const [profileView, setProfileView] = useState(false);
  const [actionsView, setActionsView] = useState(false);
  const [currenteUserData, setCurrenteUserData] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [favouritesUpdated, setFavouritesUpdated] = useState(false);
  const [errorLabel, setErrorLabel] = useState(false);
  const [userCount, setUserCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  
  const visibleUsers = nearbyUsersWithDistance.slice(
    0,
    currentPage * ITEMS_PER_PAGE
  );

/*   const hasMoreUsers = nearbyUsersWithDistance.length > visibleUsers.length;

  const loadMoreUsers = () => {
    setCurrentPage(currentPage + 1);
    setVisibleUsers(prevVisibleUsers => [
      ...prevVisibleUsers,
      ...nearbyUsersWithDistance.slice(
        prevVisibleUsers.length, 
        prevVisibleUsers.length + ITEMS_PER_PAGE
      ),
    ]);
  }; */

  const hasMoreUsers = nearbyUsersWithDistance.length > visibleUsers.length;

  const loadMoreUsers = () => {
    setCurrentPage(currentPage + 1); // Increment the page BEFORE updating visibleUsers
  };

  
  

  useEffect(() => {
    /* const fetchNearbyUsers = async (currentUserData) => {
      try {
        const usersRef = collection(db, 'users'); // Assuming users collection reference
        const usersSnapshot = await getDocs(usersRef);

        const usersWithDistance = await Promise.all(
          usersSnapshot.docs.map(async (doc) => {
            const mappedUserData = doc.data();
            const locationData = locationsJson.find((location) => location.name === mappedUserData.location);
      

            if (locationData) {
              ////console.log("Checking distance to user")
              const { latitude, longitude } = locationData;
              
              const userGeopoint = new GeoPoint(latitude, longitude);
              ////console.log(userGeopoint)
              const distance = await calculateDistanceTo(userGeopoint);
              ////console.log(distance)
              const age = await calculateAge(mappedUserData.birthday);

              // Check for blocked users and profile visibility
              const isBlocked = mappedUserData.blocked_uids.includes(user.uid) || mappedUserData.blocked_by_uids.includes(user.uid);
              const showProfile = typeof mappedUserData.hideProfile === 'undefined' ? true : !mappedUserData.hideProfile; // Default to true if field is missing
              const meetsUserAgeRange = age >= currentUserData.partner_min_age && age <= currentUserData.partner_max_age;
              //const meetsUserGenderInterest = currentUserData.gender_interest === "Sukupuolella ei väliä" || mappedUserData.gender === currentUserData.gender_interest;
              const meetsUserGenderInterest =
                (currentUserData.gender_interest === "Sukupuolella ei väliä" ||
                  mappedUserData.gender === currentUserData.gender_interest) &&
                (mappedUserData.gender_interest === "Sukupuolella ei väliä" ||
                  currentUserData.gender === mappedUserData.gender_interest);
                  ////console.log("Conditions for showing users: "+isBlocked+" profile: "+!showProfile+" age range: "+!meetsUserAgeRange+" gender interest: "+!meetsUserGenderInterest+" myself: "+doc.id === user.uid)
 
              if (isBlocked || !showProfile || !meetsUserAgeRange || !meetsUserGenderInterest || doc.id === user.uid) {
                ////console.log("Conditions for showing users: "+isBlocked+" profile: "+!showProfile+" age range: "+!meetsUserAgeRange+" gender interest: "+!meetsUserGenderInterest+" myself: "+doc.id === user.uid)
                return null; // Skip blocked users, hidden profiles, or those not matching preferences
              }

              return { ...mappedUserData, id: doc.id, distance, age };
            } else {
              console.warn(`Location data not found for user: ${user.name}`);
              return null; // Skip users without location data
            }
          })
        );

        const filteredUsers = usersWithDistance.filter((user) => user !== null);
        setNearbyUsersWithDistance(filteredUsers.sort((a, b) => a.distance - b.distance)); // Sort by distance (ascending)
      } catch (error) {
        console.error('Error fetching nearby users:', error);
      }
    };
 */

    const fetchNearbyUsers = async (currentUserData) => {
      try {
        const usersRef = collection(db, "users"); // Assuming users collection reference
        const usersSnapshot = await getDocs(usersRef);

        const usersWithDistance = await Promise.all(
          usersSnapshot.docs.map(async (doc) => {
            const mappedUserData = doc.data();

            // Check if user is liked by current user (highest priority)
            const isLikedByUser =
              currentUserData.likes_uids &&
              currentUserData.likes_uids.includes(doc.id);

            //console.warn("Mapping: " +mappedUserData.name);
            // Check for new "last_location" field with GeoPoint data and use it directly if available
            const exactGeopoint =
              mappedUserData.last_location &&
              typeof mappedUserData.last_location.latitude === "number"
                ? mappedUserData.last_location
                : null;

            let distance = 0;

            if (exactGeopoint) {
              distance = await calculateDistanceTo(exactGeopoint);
              //const age = await calculateAge(mappedUserData.birthday);
              //console.warn("Exact geopoint found for: " +mappedUserData.name);
              // ... rest of your logic using distance, age, etc. (same as before)
            } else {
              const trimmedAndLoweredUserLocation = mappedUserData.location
                .trim()
                .toLowerCase();
              const locationData = await locationsJson.find(
                (location) =>
                  location.name.trim().toLowerCase() ===
                  trimmedAndLoweredUserLocation
              );

              //const locationData = await locationsJson.find((location) => location.name === mappedUserData.location);
              /* //console.log(currentUserData.partner_min_age)
              //console.log(currentUserData.gender_interest) */
              console.log(
                "Trying to get locationData for: " +
                  mappedUserData.location +
                  " and user " +
                  mappedUserData.name
              );
              console.log(locationData);
              if (locationData) {
                ////console.log("Checking distance to user")
                const { latitude, longitude } = locationData;

                const userGeopoint = new GeoPoint(latitude, longitude);
                ////console.log(userGeopoint)
                distance = await calculateDistanceTo(userGeopoint);
              } else {
                //console.warn("Location data not found for user: " +mappedUserData.name);
                distance = 55; //assume 55 km for people who don't have valid location from legacy system
                //return null; // Skip users without location data
              }
            }
            ////console.log(distance)
            const age = await calculateAge(mappedUserData.birthday);

            if (isLikedByUser) {
              //console.log("Putting user "+mappedUserData.name+" in the group because of favourite")
              return { ...mappedUserData, id: doc.id, distance, age };
            }

            // Check for blocked users and profile visibility
            const isBlocked =
              mappedUserData.blocked_uids.includes(user.uid) ||
              mappedUserData.blocked_by_uids.includes(user.uid);
            const showProfile =
              typeof mappedUserData.hideProfile === "undefined"
                ? true
                : !mappedUserData.hideProfile; // Default to true if field is missing
            const meetsUserAgeRange =
              age >= currentUserData.partner_min_age &&
              age <= currentUserData.partner_max_age;
            //const meetsUserGenderInterest = currentUserData.gender_interest === "Sukupuolella ei väliä" || mappedUserData.gender === currentUserData.gender_interest;
            // Try converting distance_for_meets to a number, handle potential errors
            let userTravelDistance;
            try {
              userTravelDistance = parseInt(
                currentUserData.distance_for_meets,
                10
              );
            } catch (error) {
              console.error(
                "Error converting distance_for_meets to number:",
                error
              );
              userTravelDistance = 0; // Set a default value (consider a more appropriate fallback if needed)
            }

            const meetsDistanceRange = distance <= userTravelDistance;
            const meetsUserGenderInterest =
              (currentUserData.gender_interest === "Sukupuolella ei väliä" ||
                mappedUserData.gender === currentUserData.gender_interest) &&
              (mappedUserData.gender_interest === "Sukupuolella ei väliä" ||
                currentUserData.gender === mappedUserData.gender_interest);
            ////console.log("Conditions for showing users: "+isBlocked+" profile: "+!showProfile+" age range: "+!meetsUserAgeRange+" gender interest: "+!meetsUserGenderInterest+" myself: "+doc.id === user.uid)
            //console.log("Conditions for excluding "+mappedUserData.name+" from list, blocked: "+isBlocked+" profile: "+!showProfile+" age range: "+!meetsUserAgeRange+" distance range: "+!meetsDistanceRange+" gender interest: "+!meetsUserGenderInterest+" myself: "+doc.id === user.uid)
            //console.log("Conditions for excluding: " + meetsDistanceRange + " distance: " + distance + " range: " + currentUserData.distance_for_meets + " for user " + mappedUserData.name);

            if (
              isBlocked ||
              !showProfile ||
              !meetsUserAgeRange ||
              !meetsDistanceRange ||
              !meetsUserGenderInterest ||
              doc.id === user.uid
            ) {
              return null; // Skip blocked users, hidden profiles, or those not matching preferences
            }

            return { ...mappedUserData, id: doc.id, distance, age };
          })
        );

        const filteredUsers = usersWithDistance.filter((user) => user !== null);
        setNearbyUsersWithDistance(
          filteredUsers.sort((a, b) => a.distance - b.distance)
        ); // Sort by distance (ascending)
      } catch (error) {
        console.error("Error fetching nearby users:", error);
      }
    };

    // Fetch current user's likes (optional optimization)
    /* const fetchUserData = async () => {
      if (user) {
        const userRef = doc(collection(db, 'users'), user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists) {
          setIsHidden(userSnap.data().hideProfile || false); // Set default if missing
          setUserLikes(userSnap.data().likes_uids || []); // Set likes if exist
          //console.log("Setting userlikes to " + userSnap.data().likes_uids.length)
          //console.log(userSnap.data())
          if (userSnap.data().likes_uids.length >= 20) {
            //console.log("Maksimi täynnä ")
            setErrorLabel(true);
    
          } else {
            //console.log("Maksimi ei vielä täynnä ")
            setErrorLabel(false);
          }
          //setCurrentUserData(userSnap.data())
          fetchNearbyUsers(userSnap.data());
        }
      }
    }; */

    const fetchUserData = async () => {
      if (user) {
        const userRef = doc(collection(db, "users"), user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists) {
          const userData = userSnap.data();

          // Set default values for missing fields
          const likes = userData.likes_uids || [];
          const likedBy = userData.liked_by_uids || [];

          setIsHidden(userData.hideProfile || false);
          setUserLikes(likes); // Set likes array

          ////console.log("Setting userlikes to " + likes.length);
          ////console.log(userData);

          // Check for missing fields and update if necessary
          if (!userData.hasOwnProperty("likes_uids")) {
            await updateDoc(userRef, { likes_uids: [] });
          }
          if (!userData.hasOwnProperty("liked_by_uids")) {
            await updateDoc(userRef, { liked_by_uids: [] });
          }

          if (likes.length >= 20) {
            ////console.log("Maksimi täynnä ");
            setErrorLabel(true);
          } else {
            ////console.log("Maksimi ei vielä täynnä ");
            setErrorLabel(false);
          }

          fetchNearbyUsers(userSnap.data());
        }
      } 
    };

    const fetchLocations = async () => {
      setLocations(locationsJson);
    };

    /* const fetchData = async () => {
      try {
        await fetchLocations();
        await fetchUserData(); // Wait for user data to be fetched
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); */

    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading state to true
        await fetchLocations();
        await fetchUserData();
        setIsLoading(false); // Set loading state to false after fetching
      } catch (error) {
        console.error("Error fetching data:", error);
        
          try {
            const usersRef = collection(db, "users");
            const usersQuery = query(usersRef); // Get a query for all users
            const querySnapshot = await getDocs(usersQuery);
      
            const userCount = querySnapshot.size; // Get the number of users
      
            // Set the user count state variable (assuming you have one)
            setUserCount(userCount);
      
          } catch (error) {
            console.error("Error fetching user count:", error);
            // Handle any errors during user count fetching
          }
        }
      
    };

    fetchData();

    // Update effect only when currentUser or blocked_uids change
  }, [favouritesUpdated]);

  const getTags = () => {
    /*  return (
       <div className="tags">
         {dataBubbles.map((bubble, index) => (
           <div key={index} className="tag" style={{ backgroundColor: getBackgroundColor(bubble) }}>
             {bubble}
           </div>
         ))}
       </div>
     ); */
  };

  /* const handleLikeClick = async (userId, isLiked) => {
    try {
      const currentUserRef = doc(collection(db, 'users'), user.uid);
      const selectedUserRef = doc(collection(db, 'users'), userId);
  
      // Update current user's likes
      await updateDoc(currentUserRef, {
        likes_uids: isLiked ? arrayUnion(userId) : arrayRemove(userId),
      });
  
      // Update selected user's liked_by (add/remove current user's ID)
      await updateDoc(selectedUserRef, {
        liked_by_uids: isLiked ? arrayUnion(user.uid) : arrayRemove(user.uid),
      });
  
      // Update local likes state (optional)
     
  
      // Update local likes state appropriately
      setUserLikes((prevLikes) => {
        if (prevLikes.includes(userId)) {
          return prevLikes.filter((like) => like !== userId); // Unlike
        } else {
          return [...prevLikes, userId]; // Like
        }
      });
  
      //console.log('User like updated successfully!');
  
  
  
      setProfileView(false); // Assuming you set this for profile view
      setUserToBeShown(null); // Assuming this manages selected user state
  
      setFavouritesUpdated((prevCount) => prevCount + 1);
    } catch (error) {
      console.error('Error liking user:', error);
      setProfileView(false); // Reset view state in case of error
      setUserToBeShown(null); // Reset selected user state in case of error
    }
  }; */

  const handleLikeClick = async (userId, isLiked) => {
    try {
      //setErrorLabel(null);
      const currentUserRef = doc(collection(db, "users"), user.uid);
      const selectedUserRef = doc(collection(db, "users"), userId);

      // Check if user already has 20 likes before liking
      const currentLikesSnapshot = await getDoc(currentUserRef);
      const currentLikes = currentLikesSnapshot.data()?.likes_uids || [];

      if (isLiked && currentLikes.length >= 20) {
        setErrorLabel("Sinulla on jo 20 suosikkia");
        return;
      }

      // Update current user's likes
      await updateDoc(currentUserRef, {
        likes_uids: isLiked ? arrayUnion(userId) : arrayRemove(userId),
      });

      // Update selected user's liked_by (add/remove current user's ID)
      await updateDoc(selectedUserRef, {
        liked_by_uids: isLiked ? arrayUnion(user.uid) : arrayRemove(user.uid),
      });

      // Update local likes state appropriately
      setUserLikes((prevLikes) => {
        if (isLiked) {
          return [...prevLikes, userId]; // Like
        } else {
          return prevLikes.filter((like) => like !== userId); // Unlike
        }
      });

      ////console.log('User like updated successfully!');

      /* Omit toast messages for brevity */

      setProfileView(false); // Assuming you set this for profile view
      setUserToBeShown(null); // Assuming this manages selected user state

      setFavouritesUpdated((prevCount) =>
        isLiked ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error("Error liking user:", error);
      setProfileView(false); // Reset view state in case of error
      setUserToBeShown(null); // Reset selected user state in case of error
    }
  };

  const toggleProfile = (profileUid) => {
    ////console.log("Setting view to " + view)
    setProfileView(true);
    setUserToBeShown(profileUid);
  };

  const toggleDefault = () => {
    ////console.log("Setting view to " + view)

    setActionsView(false);
    setProfileView(false);
    setUserToBeShown(null);
  };

  /* const addToFavourites = (addedUser) => {
    ////console.log("Setting view to " + view)
  
  
    setProfileView(false);
    setUserToBeShown(null);
  
  };
  
  const removeFromFavourites = () => {
    ////console.log("Setting view to " + view)
  
  
    setProfileView(false);
    setUserToBeShown(null);
  
  }; */

  const toggleActions = () => {
    //Eli: Vain yksi tähti, se on tyhjä jos vastapuoli on tykännyt, ja täysi jos sinäkin olet tykännyt
    //Ja tähti puuttuu kokonaan jos kumpikaan ei ole tykännyt. Tykkääminen tapahtuu profiilinäkymän kautta
    //Lisäksi voisi olla se kehys vielä jos molemmat ovat tykänneet
    ////console.log("Setting view to " + view)

    setProfileView(false);
    setActionsView(true);
    setUserToBeShown(null);
  };

  /* const handleOpenUserProfile = async (userId) => {
    try {
      const userRef = doc(collection(db, 'users'), userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists) {
        setSelectedUser(userSnap.data()); // Set selected user data
      } else {
        console.error('User not found:', userId);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }; */

  /* const handleClosePopup = () => {
    setSelectedUser(null);
  }; */

  return (
    <div className="nearby-container">
      {profileView && (
        <div className="userprofile-modal">
          <div className="userprofile-content">
            <UserPreview uid={userToBeShown} />
            <div class="nearby-user-button-container">
              <button
                className="nearby-user-button"
                onClick={() => toggleDefault()}
              >
                <IconSquareX /> Sulje Profiili
              </button>

              {/*               {userLikes?.includes(userToBeShown) ? (
                <button className="nearby-user-button" onClick={() => handleLikeClick(userToBeShown, false)}>
                  <IconStarOff />  Poista Suosikeista
                </button>
              ) : (
              
                <button className="nearby-user-button" disabled={errorLabel} onClick={() => handleLikeClick(userToBeShown, true)}>
                  <IconStarFilled />  Lisää Suosikkeihin
                </button>
           
              )} */}

              {userLikes?.length >= 20 &&
              !userLikes?.includes(userToBeShown) ? (
                <button className="nearby-user-button" disabled={true}>
                  <IconStarFilled /> Liikaa suosikkeja
                </button>
              ) : userLikes?.includes(userToBeShown) ? (
                <button
                  className="nearby-user-button"
                  onClick={() => handleLikeClick(userToBeShown, false)}
                >
                  <IconStarOff /> Poista suosikeista
                </button>
              ) : (
                <button
                  className="nearby-user-button"
                  disabled={errorLabel}
                  onClick={() => handleLikeClick(userToBeShown, true)}
                >
                  <IconStarFilled /> Lisää suosikkeihin
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {actionsView && (
        <div className="userprofile-modal">
          <div className="userprofile-content">
            <p>
              Tähtikuvakkeet profiilikuvissa kertovat, miten sinä ja tämä
              käyttäjä olette toisenne kanssa vuorovaikuttaneet.
            </p>

            <p>
              <IconStar
                size={30}
                style={{
                  color: "grey",
                  backgroundColor: "#000000",
                  padding: "2px",
                  borderRadius: "50%",
                }}
              />
              = Kumpikaan ette ole vielä toistenne suosikkeja.
            </p>

            <p>
              <IconStarFilled
                size={30}
                style={{
                  color: "#CD7F32",
                  backgroundColor: "#000000",
                  padding: "2px",
                  borderRadius: "50%",
                }}
              />
              = Olet lisännyt hänet suosikiksi.
            </p>

            <p>
              <IconStarFilled
                size={30}
                style={{
                  color: "silver",
                  backgroundColor: "#000000",
                  padding: "2px",
                  borderRadius: "50%",
                }}
              />
              = Sinut on lisätty tämän käyttäjän suosikiksi.
            </p>

            <p>
              <IconStarFilled
                size={30}
                style={{
                  color: "gold",
                  backgroundColor: "#000000",
                  padding: "2px",
                  borderRadius: "50%",
                }}
              />
              = Olette molemmat toistenne suosikkeja.
            </p>

            <p>
              Miksi et loisi deittejä nähdäksesi haluaako hän tavata? Voit
              valita tapaamisen näkyvän vain suosikeillesi. Suosikkisi eivät
              tiedä näkyykö tapaamisesi kaikille, vai pelkästään heille.
            </p>

            <p>
              Mikäli piilotat profiilisi, sinut poistetaan automaattisesti sinut
              suosikiksi lisänneiden listoilta. Mikäli sinulta puolestaan katoaa
              itsestään suosikkeja, se johtuu samaisesta syystä.
            </p>

            <div class="nearby-user-button-container">
              <button
                className="nearby-user-button"
                onClick={() => toggleDefault()}
              >
                <IconSquareX /> Sulje
              </button>
            </div>
          </div>
        </div>
      )}
      <strong>Lähellä olevat ihmiset </strong>
      <br />
      <span>{userLikes?.length}/20 suosikkia valittu</span>
      <br /> 
            <br />
      {isHidden ? (
        <div>
          Profiilisi on tällä hetkellä piilotettuna, joten et näe lähistöllä
          olevia käyttäjiä ({nearbyUsersWithDistance.length} kpl), eivätkä he
          sinua.
        </div>
      ) : nearbyUsersWithDistance.length > 0 ? (
        <div>
        <div className="user-grid">
          {/* {nearbyUsersWithDistance.map((profile) => (  */}
          {visibleUsers.map((profile) => (
            <div key={profile.uid} className="user-card">
              <img
                src={profile.thumbUrl || placeholderImage}
                alt={profile.name}
                onClick={() => toggleProfile(profile.uid)} // Open popup on image click
              />
              <div className="star-icon" onClick={() => toggleActions()}>
                {profile.likes_uids?.includes(user.uid) &&
                userLikes?.includes(profile.uid) ? (
                  <IconStarFilled
                    size={30}
                    style={{
                      color: "gold",
                      backgroundColor: "#000000",
                      padding: "2px",
                      borderRadius: "50%",
                    }}
                  />
                ) : profile.likes_uids?.includes(user.uid) ? (
                  <IconStarFilled
                    size={30}
                    style={{
                      color: "silver",
                      backgroundColor: "#000000",
                      padding: "2px",
                      borderRadius: "50%",
                    }}
                  />
                ) : userLikes?.includes(profile.uid) ? (
                  <IconStarFilled
                    size={30}
                    style={{
                      color: "#CD7F32",
                      backgroundColor: "#000000",
                      padding: "2px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <IconStar
                    size={30}
                    style={{
                      color: "grey",
                      backgroundColor: "#000000",
                      padding: "2px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </div>
              <div
                className="user-info"
                onClick={() => toggleProfile(profile.uid)}
              >
                <span>
                  {profile.name}, {profile.age}
                </span>
                {/*  <br /> */}
                <span>{profile.distance} KM</span>
              </div>
              {/* ... rest of user card content 
              {getTags()} */}
            </div>
          ))}
          
        </div>
        <br/>
        {hasMoreUsers && (
          <div>
        
            <button onClick={loadMoreUsers} className="load-more-button">
              Näytä lisää
            </button>
            <br/>
            <small>1. Valikoi tässä näkymässä ihmiset jotka olisit valmis tapaamaan</small>
            <br/>
            <small>2. Luo uusi oma tapaaminen joka näkyy vain heille tai halutessasi kaikille</small>
            <br/>
            <small>3. Slipperissä osuma syntyy ja keskusteluyhteys avautuu vain treffipyyntöjen kautta</small>
           <br/>
           <br/>
          </div>
        )}
        </div>
      ) : (
        <div>
          <p>Läheltä ei löytynyt yhtään käyttäjää tai et ilman profiilia pysty
          heitä selaamaan. Muuta hakukriteereitäsi tai luo itsellesi profiili oikeassa yläkulmassa olevasta ikonista.</p>
          <p>Slipperissä on tällä hetkellä <b>{userCount}</b> ihmistä, kenties kiinnostuneena kohtaamaan juuri sinut.</p>
        </div>
        
      )}
    </div>
  );
};

export default Nearby;
