import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { IconTag, IconSquareRoundedPlus, IconMessage, IconMessageHeart, IconUserCircle, IconFriends, IconShoe, IconStar } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, query, where, collection, or } from 'firebase/firestore';
import { db } from "../config/firebase";

const MainNavbar = () => {
  const { user, logOut } = UserAuth();
  const [activeLink, setActiveLink] = useState('/');
  const navigate = useNavigate();
  const location = useLocation();
  const [profileExists, setProfileExists] = useState(false);
  const [newMessages, setNewMessages] = useState(false);
  

  /* useEffect(() => {
    const doesUserDocumentExist = async () => {
      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        ////console.log("Userdoc found")
        ////console.log("Mainnavbar: profileExists:", profileExists);
        const exists = userDoc.exists()
        setProfileExists(exists)
        ////console.log("Mainnavbar: profileExists:", profileExists);

      } catch (error) {
        console.error("Error checking user document:", error);
        setProfileExists(false) // Return false if there's an error
      }
    };
    doesUserDocumentExist();
  }, [user]); */

  const displayNotification = (chat, latestMessage) => {
    if (Notification.permission === 'granted') {
      const notification = new Notification('New Message', {
        body: `New message from ${chat.senderName}`, // Replace with appropriate message
        icon: '/path/to/your/icon.png' // Replace with your icon path
      });
  
      notification.onclick = () => {
        // Handle notification click, e.g., focus the chat window
        // You might want to use focus() or other methods to bring your application to focus
      };
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          displayNotification(chat, latestMessage);
        }
      });
    }
  };
  

  useEffect(() => {

    const fetchData = async () => {
      const chatsCollectionRef = collection(db, 'chats');
      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        const profileExists = userDoc.exists();
        setProfileExists(profileExists);
    
        if (profileExists) {
        const chatsSnapshot = await getDocs(chatsCollectionRef);

        const filteredChats = chatsSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            chatData: doc.data(),
          }))
          .filter((chat) => {
            const { owner_id, applicant_id, archived } = chat.chatData;
            const currentUserUid = user.uid;
      
            return (
              (currentUserUid === owner_id || currentUserUid === applicant_id) && !archived
            );
          });
      
          ////console.log(filteredChats)

          const hasNewMessages = filteredChats.some(chat => {
            const chatData = chat.chatData; // Access chatData directly from the object
            ////console.log("Chat Data:", chatData);
          
            if (!chatData.messages || chatData.messages.length === 0) {
              ////console.log("No messages found in the chat");
              return false;
            }
          
            const latestMessage = chatData.messages[chatData.messages.length - 1];
            ////console.log("Latest Message:", latestMessage);
          
            let lastCheckTime;
          
            // Determine which lastCheckTime to use based on the user's role
            if (user.uid === chatData.owner_id) {
              lastCheckTime = chatData.lastCheckTimeOwner;
            } else if (user.uid === chatData.applicant_id) {
              lastCheckTime = chatData.lastCheckTimeApplicant;
            }
          
            ////console.log("Last Check Time:", lastCheckTime);
          
            // Check if the latest message is newer than the last check time

            if (latestMessage.timestamp > lastCheckTime) {
              // New message found!
              displayNotification(chat, latestMessage);
            }

            return latestMessage.timestamp > lastCheckTime;
          });
          
          ////console.log("Has New Messages:", hasNewMessages);
          
          

          
          // Update the state to indicate whether there are new messages
          setNewMessages(hasNewMessages);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setProfileExists(false);
      }
    };

    // Call the function to fetch data
    fetchData();
  }, [user]);


  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
    navigate(path);
  };


  return (
    <nav className="main-navbar">
      <ul>
        <li>
          <Link to="/meets" className={`nav-link ${activeLink === '/meets' ? 'active' : ''}`}>
            <IconFriends size={35} stroke={1.5} className={activeLink === '/meets' ? 'pulse-animation' : ''} />
          </Link>
        </li>
      </ul>
      {profileExists && (
        <>
          <ul>
            <li>
                  {/*    <Link to="/discussions" className={`nav-link ${activeLink === '/discussions' ? 'active' : ''}`}>
                <IconMessage size={35} stroke={1.5} className={activeLink === '/discussions' ? 'pulse-animation' : ''} />
              </Link>  */}
       {/* <Link to="/discussions" className={`nav-link ${activeLink === '/discussions' ? 'active' : ''}`}>
                {newMessages ? (
                  <IconMessageHeart size={35} stroke={1.5} className={`pulse-animation red-icon ${activeLink === '/discussions' ? 'active' : ''}`} />
                ) : (
                  <IconMessage size={35} stroke={1.5} className={activeLink === '/discussions' ? 'pulse-animation active' : ''} />
                )}
              </Link>  */}
<Link to="/discussions" className={`nav-link ${activeLink === '/discussions' ? 'active' : ''}`}>
  {newMessages ? (
    <IconMessageHeart size={35} stroke={1.5} className={`red-icon ${activeLink === '/discussions' ? 'pulse-animation' : ''}`} />
  ) : (
    <IconMessage size={35} stroke={1.5} className={activeLink === '/discussions' ? 'pulse-animation' : ''} />
  )}
</Link>


            </li>
          </ul>
          <ul>
            <li>
              <Link to="/mymeeteditor" className={`nav-link ${activeLink === '/mymeeteditor' ? 'active' : ''}`}>
                <IconSquareRoundedPlus size={35} stroke={1.5} className={activeLink === '/mymeeteditor' ? 'pulse-animation' : ''} />
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/shop" className={`nav-link ${activeLink === '/shop' ? 'active' : ''}`}>
                <IconTag size={35} stroke={1.5} className={activeLink === '/shop' ? 'pulse-animation' : ''} />
              </Link>
            </li>
          </ul>
        </>
      )}
      <ul>
        <li>
          <Link to="/account" className={`nav-link ${activeLink === '/account' ? 'active' : ''}`}>
            <IconUserCircle size={35} stroke={1.5} className={activeLink === '/account' ? 'pulse-animation' : ''} />
          </Link>
        </li>
      </ul>

      {/* The rest of your code */}
    </nav>
  );




};

export default MainNavbar;
